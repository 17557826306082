import React, { useState } from "react";
import { Link } from "gatsby";
import {
  MDBAnimatedNavbar,
  MDBContainer,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarBrand,
  MDBCollapse,
} from "mdb-react-ui-kit";
import { StaticImage } from "gatsby-plugin-image";

import ARGANavbarLocalItem from "../../components/ARGANavbarLocalItem";
import ARGANavbarExternalItem from "../../components/ARGANavbarExternalItem";

const Navbar = () => {
  const [showBasic, setShowBasic] = useState(false);

  return (
    <header>
      <MDBAnimatedNavbar
        dark
        expand="lg"
        fixed="top"
        onBlur={() => setShowBasic(false)}
      >
        <MDBContainer>
          <MDBNavbarBrand onClick={() => setShowBasic(false)}>
            <Link to="/">
              <StaticImage
                src="../../assets/images/Logo-C.png"
                height="40"
                alt=""
                loading="lazy"
                placeholder="tracedSVG"
                aria-label="ARGA logo"
              />
            </Link>
          </MDBNavbarBrand>

          <MDBNavbarToggler
            tag="button"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowBasic(!showBasic)}
          >
            <MDBIcon className="text-white" fas icon="bars" />
          </MDBNavbarToggler>

          <MDBCollapse
            navbar
            show={showBasic}
            className="justify-content-between"
          >
            <MDBNavbarNav fullWidth={false} className="mb-2 mb-lg-0">
              <ARGANavbarLocalItem
                item="Beneficios"
                rippleColor="#65C0B7"
                rippleDuration={5000}
                url="/#benefits"
                setShowBasic={setShowBasic}
              />
              <ARGANavbarLocalItem
                item="Servicios"
                rippleColor="#65C0B7"
                rippleDuration={5000}
                url="/#services"
                setShowBasic={setShowBasic}
              />
              <ARGANavbarLocalItem
                item="Acerca de ARGA"
                rippleColor="#65C0B7"
                rippleDuration={5000}
                url="/#presentation"
                setShowBasic={setShowBasic}
              />
              <ARGANavbarLocalItem
                item="Blog"
                rippleColor="#65C0B7"
                rippleDuration={5000}
                url="/blog"
                setShowBasic={setShowBasic}
              />
              <ARGANavbarLocalItem
                item="Contacto"
                rippleColor="#65C0B7"
                rippleDuration={5000}
                url="/#contact"
                setShowBasic={setShowBasic}
              />
            </MDBNavbarNav>

            <MDBNavbarNav
              fullWidth={false}
              className="flex-row justify-content-center align-items-center"
            >
              <ARGANavbarExternalItem
                className="WA"
                icon="whatsapp"
                url="https://wa.link/s796d9"
                setShowBasic={setShowBasic}
              />
              <ARGANavbarExternalItem
                className="EM"
                icon="at"
                url="mailto:info@arga.cl"
                setShowBasic={setShowBasic}
              />
              <ARGANavbarExternalItem
                className="YH"
                icon="youtube"
                url="https://www.youtube.com/channel/UC_nS5AB-eDZhVI22oDpv7_g"
                setShowBasic={setShowBasic}
              />

              <ARGANavbarExternalItem
                className="FH"
                icon="facebook-f"
                url="https://www.facebook.com/ARGAIntercultural"
                setShowBasic={setShowBasic}
              />

              <ARGANavbarExternalItem
                className="IH"
                icon="instagram"
                url="https://www.instagram.com/argaintercultural"
                setShowBasic={setShowBasic}
              />

              <ARGANavbarExternalItem
                className="LH"
                icon="linkedin"
                url="https://www.linkedin.com/company/arga-intercultural"
                setShowBasic={setShowBasic}
              />
              <StaticImage
                src="../../assets/images/Chile-A.png"
                height="40"
                alt="chile"
                loading="lazy"
                placeholder="tracedSVG"
                aria-label="Chile logo"
              />
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBAnimatedNavbar>
    </header>
  );
};

export default Navbar;
