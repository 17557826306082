import React from "react";
import { MDBFooter } from "mdb-react-ui-kit";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Footer = () => (
  <MDBFooter
    bgColor="light"
    className="text-lg-center text-center border-5 border-top text-muted pb-5 pt-3"
    id="footer"
  >
    {/* Social media */}
    <section className="py-3 my-2 justify-content-center d-flex">
      {/* Youtube */}
      <a
        class="btn text-white btn-floating m-1"
        style={{ backgroundColor: "#ed302f" }}
        href="https://www.youtube.com/channel/UC_nS5AB-eDZhVI22oDpv7_g"
        target="_blank"
        rel="noreferrer"
        role="button"
        aria-label="ARGA YouTube channel"
      >
        <i class="fab fa-youtube"></i>
      </a>

      {/* Facebook */}
      <a
        class="btn text-white btn-floating m-1"
        style={{ backgroundColor: "#3b5998" }}
        href="https://www.facebook.com/ARGAIntercultural"
        target="_blank"
        rel="noreferrer"
        role="button"
        aria-label="ARGA Facebook page"
      >
        <i class="fab fa-facebook-f"></i>
      </a>

      {/* Instagram */}
      <a
        class="btn text-white btn-floating m-1"
        style={{ backgroundColor: "#ac2bac" }}
        href="https://www.instagram.com/argaintercultural"
        target="_blank"
        rel="noreferrer"
        role="button"
        aria-label="ARGA Instagram feed"
      >
        <i class="fab fa-instagram"></i>
      </a>

      {/* Linkedin */}
      <a
        class="btn text-white btn-floating m-1"
        style={{ backgroundColor: "#0082ca" }}
        href="https://www.linkedin.com/company/arga-intercultural"
        target="_blank"
        rel="noreferrer"
        role="button"
        aria-label="ARGA LinkedIn profile"
      >
        <i class="fab fa-linkedin-in"></i>
      </a>
    </section>

    <hr
      class="mx-auto text-info mt-3 mb-4"
      style={{ width: "100px", height: "3px" }}
    />
      <StaticImage
                src="../../assets/images/Chile-B.png"
                className="text-center"
                height="100"
                alt="chile"
                loading="lazy"
                placeholder="tracedSVG"
                aria-label="Chile logo"
              />

    {/* Descripcion y maps  */}
    <section>
      <div class="container d-flex justify-content-center text-black  mt-3">
      

        {/* Fila de la grilla */}
        <div class="row  mt-3">
          {/* Contacto */}
          <div class="col-md-4 mb-md-0 mb-4 text-md-start">
            <p class="text-uppercase fw-bold mb-4">Contacto</p>
            <i class="fas fs-xs fa-home me-3"></i>{" "}
            <p>La Capitanía 70, Las Condes, Región Metropolitana, Chile.</p>
            <p>Mariano Fragueiro 1324, Córdoba, Argentina.</p>
            <p>
              <i class="fas fs-xs fa-envelope me-3"></i>
              <a href="mailto:info@arga.cl">
                info@arga.cl
              </a>
            </p>
            <p>
              <i class="fas fs-xs fa-phone me-3"></i> 
              <a target="_blank" href="https://wa.link/s796d9">
                +54 9 353 406-5659
              </a>
            </p>
          </div>

          {/* Maps Chile */}
          <div class="col-sm-4 mx-auto mb-4">
            <p class="text-uppercase fw-bold mb-4">Chile</p>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.4857372080937!2d-70.57392368519085!3d-33.41057850285238!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cfb476910099%3A0xd36709aa8ae483b2!2sLa%20Capitan%C3%ADa%2070%2C%20Las%20Condes%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1ses-419!2sco!4v1651156971597!5m2!1ses-419!2sco"
              width="220"
              height="200"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              title="Mapa ubicación Chile"
            ></iframe>
          </div>

          {/* Maps Argentina */}
          <div class="col-sm-4 mx-auto mb-4">
            {/* Links */}
            <p class="text-uppercase fw-bold mb-4">Argentina</p>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6811.028191644849!2d-64.187424!3d-31.399958999999996!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94329864f67e3011%3A0xaebb666ebe8fb6d4!2sMariano%20Fragueiro%201324%2C%20C%C3%B3rdoba%2C%20Argentina!5e0!3m2!1ses-419!2sco!4v1645641935640!5m2!1ses-419!2sco"
              width="220"
              height="200"
              loading="lazy"
              title="Mapa ubicación Argentina"
            ></iframe>
          </div>
        </div>
      </div>
    </section>

    {/* Copyright */}
    <div class="text-center text-black p-4 mt-5">
      © 2024 Copyright :&nbsp;
      <Link class="text-reset text-black fw-bold" to="/">
        ARGA Intercultural
      </Link>
    </div>
  </MDBFooter>
);

export default Footer;
