import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { MDBNavbarItem, MDBNavbarLink } from "mdb-react-ui-kit";

const ARGANavbarLocalItem = ({ item, url, setShowBasic }) => (
  <MDBNavbarItem>
    <MDBNavbarLink onClick={() => setShowBasic(false)}>
      <AnchorLink to={url} className="link-light mx-2">
        {item}
      </AnchorLink>
    </MDBNavbarLink>
  </MDBNavbarItem>
);

export default ARGANavbarLocalItem;
