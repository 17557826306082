import React from "react";
import { MDBIcon, MDBNavbarItem, MDBNavbarLink } from "mdb-react-ui-kit";

const ARGANavbarExternalItem = ({ icon, url, setShowBasic }) => (
  <MDBNavbarItem className="mx-1">
    <MDBNavbarLink
      className="pe-2"
      href={url}
      rel="nofollow"
      target="_blank"
      onClick={() => setShowBasic(false)}
    >
      <MDBIcon fab icon={icon} />
    </MDBNavbarLink>
  </MDBNavbarItem>
);

export default ARGANavbarExternalItem;
