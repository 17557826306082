import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { MDBBtn, MDBIcon, MDBTypography } from "mdb-react-ui-kit";
import CookieConsent from "../../components/ARGACookieConsentBanner";

const ARGAWhatsappIcon = () => (
  <MDBBtn
    className="float"
    style={{ backgroundColor: "#25d366" }}
    href="https://wa.link/s796d9"
    target="_blank"
  >
    <div className="d-flex justify-content-center align-items-center">
      <MDBIcon fab icon="whatsapp" className="pt-1" />
    </div>
  </MDBBtn>
);

const Layout = ({ children }) => (
  <>
    <Navbar />
    {children}
    <ARGAWhatsappIcon />
    <Footer />
    <CookieConsent />
  </>
);

export default Layout;
