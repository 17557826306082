import * as React from 'react';
import { CookieNotice } from "gatsby-cookie-notice";

const CookieConsent = () => {
  return (
    <CookieNotice
    personalizeButtonText={"Elegir cookies"}
    backgroundClasses={"container-fluid bg-light p-2"}
    acceptButtonClasses={"outline m-1"}
    acceptButtonText={"Aceptar"}
    declineButtonClasses={"outline"}
    declineButtonText={"Rechazar"}
    personalizeButtonClasses={"outline py-0 px-1"}
    personalizeValidationClasses={"outline"}
    personalizeValidationText={"Guardar"}
    cookieTitleClasses={"m-1"}
    cookies={[
            {name: 'gatsby-gdpr-google-analytics', editable: true, default: true, title: 'Google Analytics', text: '' },
            {name: 'gatsby-gdpr-google-tagmanager', editable: true, default: true, title: 'Google Tag Manager', text: '' },
            {name: 'gatsby-gdpr-facebook-pixel', editable: true, default: true, title: 'Facebook Pixel', text: '' }
        ]}>
        <h4>Este sitio utiliza cookies.</h4>
        <p>Usamos cookies para que el sitio funcione mejor y también para conocer cómo interactúas con él. Solo utilizaremos cookies si nos lo permites haciendo clic en "Aceptar". También puedes elegir cuáles cookies deseas permitir.</p>
    </CookieNotice>
  );
};

export default CookieConsent;